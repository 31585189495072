import { css } from '@emotion/react'
import { OrusHorizontalLogoImage } from '@orus.eu/pharaoh/src/skin/default-skin/orus-horizontal-logo-image'
import { memo } from 'react'

export const PoweredByOrus = memo(function PoweredByOrus() {
  return (
    <div
      css={css`
        display: flex;
        height: 68px;
        padding: 8px 38px;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          height: 52px;
          padding: 17px 46px 17px 43px;
          justify-content: center;
          align-items: flex-start;
          gap: 18px;
          text-align: center;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.3px;
          color: #ded1f9;
          background-color: #030222;
          border-radius: 12px;
        `}
      >
        <span>Powered by</span>
        <OrusHorizontalLogoImage width={52} height={16} color="#ded1f9" />
      </div>
    </div>
  )
})
